import React from 'react';
import { CountryPageProps } from './interfaces/ComponentProps';

function HomePage({ country }: CountryPageProps) {
  let currentPath = window.location.pathname;

  if (currentPath.endsWith('/')) {
    currentPath = currentPath.slice(0, -1);
  }

  React.useEffect(() => {
    window.location.replace(`${currentPath}/help/${country}`);
  });

  return (
    <></>

  );

}


export default HomePage;