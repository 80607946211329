export class Utility {


    
    public static isJsonEmpty(json: any): boolean {
        return json && Object.keys(json).length === 0 && json.constructor === Object;
    }

    public static isUndefinedOrNull(input: any): boolean {
        return input === undefined || input === null;
    }


    public static getCurrentEnvironment(): string {
        var ret: string = 'PROD';

        if (window.location.href.toLowerCase().indexOf('https://' + process.env.REACT_APP_TenantNameDev) !== -1)
            ret = 'DEV';

        return ret;
    }

    public static getCurrentLanguageID(languageCode: string): string {
        // get current user languageCode
        const currentUserLanguage: string = languageCode;
        // get language id (es: "IT" from "it-it" language code)
        let languageSplit: string[] = currentUserLanguage.split('-');
        let languageId: string = languageSplit[1] ? languageSplit[1].toUpperCase() : '';

        return languageId;
    }

    public static getValue(valuesArray: string[]): string {
        //valuesArray = [value_PROD, value_DEV]
        var currentEnvironment = this.getCurrentEnvironment();
        var ret = '';
        if (!this.isUndefinedOrNull(valuesArray)) {
            if (valuesArray.length > 0) {
                if (currentEnvironment === 'PROD') {
                    ret = valuesArray[0];
                }
                if (currentEnvironment === 'DEV') {
                    ret = valuesArray[1];
                }
            }
        }

        return ret;
    }

    public static maskEmail(email: string): string {
        if (email.length <= 4) {
          return email;
        }
        const firstTwo = email.slice(0, 2);
        const lastTwo = email.slice(-2);
      
        const maskLength = email.length - 4;
        const mask = '*'.repeat(maskLength);

        return firstTwo + mask + lastTwo;
      }

}