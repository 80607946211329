export enum CountryEnum {
    Italy = 'it',
    Spain = 'es',
};

export const CountryName: { [key in CountryEnum]: string } = {
    'it': "Italia",
    'es': "España",
};

export const countries = Object.values(CountryEnum).sort();

export const isCountryValid = (country: string | undefined) => {
    return country !== undefined && Object.values(CountryEnum).includes(country as CountryEnum);
}

export const getSupportLinkByCountryCode = (country: CountryEnum) => {
    switch (country) {
        case CountryEnum.Italy:
            return process.env.REACT_APP_SupportPageLink_IT ?? "";
        case CountryEnum.Spain:
            return process.env.REACT_APP_SupportPageLink_ES ?? "";
        default:
            return "";
    }
}

export const getServiceMailByCountryCode = (country: CountryEnum) => {
    switch (country) {
        case CountryEnum.Italy:
            return process.env.REACT_APP_ServiceMail_IT ?? "";
        case CountryEnum.Spain:
            return process.env.REACT_APP_ServiceMail_ES ?? "";
        default:
            return "";
    }
}

export const getServicePhoneByCountryCode = (country: CountryEnum) => {
    switch (country) {
        case CountryEnum.Italy:
            return process.env.REACT_APP_ServicePhone_IT ?? "";
        case CountryEnum.Spain:
            return process.env.REACT_APP_ServicePhone_ES ?? "";
        default:
            return "";
    }
}

export const getInfoPointPhoneByCountryCode = (country: CountryEnum) => {
    switch (country) {
        case CountryEnum.Italy:
            return process.env.REACT_APP_InfoPoint_Phone_IT ?? "";
        case CountryEnum.Spain:
            return process.env.REACT_APP_InfoPoint_Phone_ES ?? "";
        default:
            return "";
    }
}

export const getGDPRFileByCountryCode = (country: CountryEnum) => {
    switch (country) {
        case CountryEnum.Italy:
            return process.env.REACT_APP_GDPR_File_IT ?? "";
        case CountryEnum.Spain:
            return process.env.REACT_APP_GDPR_File_ES ?? "";
        default:
            return "";
    }
}