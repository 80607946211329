import React, { useEffect, useState } from "react";
import { DefaultButton, Label, TextField } from "@fluentui/react";
import { UserData, UserMailData } from "./Types";
import { StepCheckProps } from "./interfaces/ComponentProps";
import { Trans, useTranslation } from "react-i18next";
import i18n from "./i18n";

const StepCheckComponent = ({
  userData,
  userMailData,
  submitButtonText,
  prevButtonText,
  onSubmit,
  onPrev,
  lang
} : StepCheckProps ) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(['onBoard']);
  useEffect(() => {}, []);
  
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    i18n.changeLanguage(lang);
  },[lang])

  async function handleSubmit() {
    setLoading(true);
    await onSubmit();
    setLoading(false);
  }

  async function handlePrev() {
    setLoading(true);
    await onPrev();
    setLoading(false);
  }

  return (
    <>
      {" "}
      <div>
        {" "}
        <p><Trans t={t} i18nKey="pageCheckText" components={{ em: <em />, p: <p />, strong:<strong /> }}/></p>
        <br></br>
        <div className="div_label_summary">
          <strong><Trans t={t} i18nKey="label.SSN" components={{ em: <em />, p: <p />, strong:<strong /> }}/>: </strong>
        </div>
        <div className="div_data_summary">
          <Label>{userData.ssn}</Label>
        </div>
        <p></p>
        <div className="div_label_summary">
          <strong><Trans t={t} i18nKey="label.badge" components={{ em: <em />, p: <p />, strong:<strong /> }}/>: </strong>
        </div>
        <div className="div_data_summary">
          <Label>{userData.badgeNumber}</Label>
        </div>
        <p></p>
        <div className="div_label_summary">
          <strong><Trans t={t} i18nKey="label.email" components={{ em: <em />, p: <p />, strong:<strong /> }}/>: </strong>
        </div>
        <div className="div_data_summary">
          <Label>{userMailData.email}</Label>
        </div>
        <p></p>
        <div className="div_button_opposite">
          <DefaultButton
            className={`button_opposite ${loading ? "button_disable" : ""}`}
            disabled={loading}
            onClick={async () => await handlePrev()}
          >
            {prevButtonText}
          </DefaultButton>
          <DefaultButton
            className={`button_opposite ${loading ? "button_disable" : ""}`}
            disabled={loading}
            onClick={async () => await handleSubmit()}
          >
            {submitButtonText}
          </DefaultButton>
        </div>
      </div>
    </>
  );
};

export const StepCheck = StepCheckComponent;
