import React, { useEffect, useState } from "react";
import { DefaultButton, TextField } from "@fluentui/react";
import { UserData } from "./Types";
import { StepUserDataProps } from "./interfaces/ComponentProps";
import { Trans, useTranslation } from "react-i18next";
import i18n from "./i18n";

const SelfResetStepUserDataComponent = ({
  defaultValue, 
  nextButtonText,
  onNext,
  onValidate,
  lang
} : StepUserDataProps) => {
  const [loading, setLoading] = useState(false);
  const [badgeNumber, setBadgeNumber] = useState(defaultValue.badgeNumber);
  const [ssn, setSsn] = useState(defaultValue.ssn);
  const { t } = useTranslation(['selfReset']);
  
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    i18n.changeLanguage(lang);
  },[lang])


  useEffect(() => {
    const userData: UserData = {
      badgeNumber: badgeNumber,
      ssn: ssn,
    };
    onValidate(userData);
  }, [badgeNumber, ssn]);

  async function handleNext() {
    setLoading(true);
    await onNext("data");
    setLoading(false);
  }

  return (
    <>
      {" "}
      <p><Trans t={t} i18nKey="pageBadgeSSNText" components={{ em: <em />, p: <p />, strong:<strong /> }}/></p>
      <br></br>
      <div className="div_label">
        <strong>
          <Trans t={t} i18nKey="label.SSN" components={{ em: <em />, p: <p />, strong:<strong /> }}/>:
        </strong>
      </div>
      <div className="div_data">
        <TextField
          placeholder={t("label.SSN")}
          id="codF"
          value={ssn}
          onChange={(e) => setSsn((e.target as HTMLInputElement).value)}
        />
      </div>
      <p></p>
      <div className="div_label">
        <strong>
        <Trans t={t} i18nKey="label.badge" components={{ em: <em />, p: <p />, strong:<strong /> }}/>:
        </strong>
      </div>
      <div className="div_data">
        <TextField
          placeholder={t("label.badge")}
          id="badgeID"
          value={badgeNumber}
          onChange={(e) => setBadgeNumber((e.target as HTMLInputElement).value)}
        />
      </div>
      <div className="div_button">
        <DefaultButton
          className={loading ? "button_disabled" : "button"}
          disabled={loading}
          onClick={async () => await handleNext()}
        >
          {nextButtonText}
        </DefaultButton>
      </div>
    </>
  );
};

export const SelfResetStepUserData = SelfResetStepUserDataComponent;
