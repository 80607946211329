import React from "react";
import { ISpinnerStyles, Spinner, SpinnerSize } from '@fluentui/react';

const spinnerStyles: Partial<ISpinnerStyles> = {
  circle: {
    height: '70px',
    width: '70px',
    borderTopColor: '#1554FF', // Change spinner color here    
  },
};

export function Loading({message}: {message: string}) {
  return (
    <div className="loading-container">
      <Spinner size={SpinnerSize.large} label={""} ariaLive="assertive" styles={spinnerStyles} />
      <p className="submitted">
        <strong>
          {message}
        </strong>
      </p>
    </div>
  )
}