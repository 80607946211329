import React, { useEffect, useState } from "react";
import { DefaultButton, TextField } from "@fluentui/react";
import { UserMailData } from "./Types";
import { Trans, useTranslation } from "react-i18next";
import i18n from "./i18n";
import { StepEmailProps } from "./interfaces/ComponentProps";

const StepEmailComponent = ({
  defaultValue,
  nextButtonText,
  prevButtonText,
  onNext,
  onPrev,
  onValidate,
  lang
}: StepEmailProps) => {
  
  const { t } = useTranslation(['onBoard']);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(defaultValue.email);
  const [emailCheck, setEmailCheck] = useState(defaultValue.emailCheck);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    i18n.changeLanguage(lang);
  }, [lang])

  useEffect(() => {
    const mailData: UserMailData = {
      email: email,
      emailCheck: emailCheck,
    };
    onValidate(mailData);
  }, [email, emailCheck]);

  async function handleNext() {
    setLoading(true);
    await onNext("mail_upn");
    setLoading(false);
  }

  async function handlePrev() {
    setLoading(true);
    await onPrev();
    setLoading(false);
  }

  return (
    <>
      <div>
        {" "}
        <p><Trans t={t} i18nKey="pageConfirmEmailText" /></p>
        <br></br>
        <div className="div_label">
          <strong><Trans t={t} i18nKey="label.email" />: </strong>
        </div>
        <div className="div_data">
          {/*    <TextField placeholder="Email"  id='Email' readOnly={checkstep} onChange={(e) => validateEmail(e)}/> */}
          <TextField
            placeholder={t("label.email")}
            id="Email"
            value={email}
            onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
          />
        </div>
        <p></p>
        <div className="div_label">
          <strong><Trans t={t} i18nKey="label.confirmEmail" />: </strong>
        </div>
        <div className="div_data">
          <TextField
            placeholder={t("label.confirmEmail")}
            id="EmailConfirm"
            value={emailCheck}
            onChange={(e) =>
              setEmailCheck((e.target as HTMLInputElement).value)
            }
          />
        </div>
        <p></p>
        <div className="div_button_opposite">
          <DefaultButton
            className={`button_opposite ${loading ? "button_disable" : ""}`}
            disabled={loading}
            onClick={async () => await handlePrev()}
          >
            {prevButtonText}
          </DefaultButton>
          <DefaultButton
            className={`button_opposite ${loading ? "button_disable" : ""}`}
            disabled={loading}
            onClick={async () => await handleNext()}
          >
            {nextButtonText}
          </DefaultButton>
        </div>
      </div>
    </>
  );
};

export const StepEmail = StepEmailComponent;
