import React from 'react';
import { CountryPageProps } from './interfaces/ComponentProps';

function SelfResetRedirect({ country }: CountryPageProps) {
  React.useEffect(() => {
    const currentPath = window.location.pathname;
    const basePath = currentPath.split('/').slice(0, 2).join('/');

    window.location.replace(`${basePath}/selfReset/${country}`);
  }, [country]);

  return null;
}

export default SelfResetRedirect;
