import React, { useCallback, useEffect, useRef, useState } from "react";
import logo from "./Header_Logo.png";
import header_img_dark from "./header.jpg";
import { SupportLinks } from "./SupportLinks";
import { StepCompleted } from "./StepCompleted";
import { StepCompletedError } from "./StepCompletedError";
import { useLocation } from "react-router-dom";
import { SiteHelper } from "./helpers/SiteHelper";
import { Loading } from "./Loading";
import { PageProps } from "./interfaces/ComponentProps";
import i18n from "./i18n";
import { useTranslation } from "react-i18next";

type OnBoardState = {
  loading: boolean;
  error: boolean;
  errorMessage: string;
};

export function OnBoardComplete({country, lang}: PageProps) {
  const { t } = useTranslation(['onBoard']);
  const [state, setState] = useState<OnBoardState>({
    loading: true,
    error: false,
    errorMessage: "",
  });
  const { loading, error, errorMessage } = state;
  const search = useLocation().search;
  const requestKey = new URLSearchParams(search).get("requestKey");

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    i18n.changeLanguage(lang);
  }, [lang])
  
  useEffect(() => {
    if (requestKey) {
      console.log("requestKey: " + requestKey);

      const completeRequest = async () => {
        const errMsg = await SiteHelper.CompleteRequest(requestKey??"", lang);
        if (errMsg === "") {
          setState({
            loading: false,
            error: false,
            errorMessage: "",
          });
        } else {
          setState({
            loading: false,
            error: true,
            errorMessage: (errMsg as string) ?? "",
          });
        }
      };

      // call the function
      const errorMsg = completeRequest()
        // make sure to catch any error
        .catch((err) => {
          setState({
            loading: false,
            error: true,
            errorMessage: (err as string) ?? "",
          });
        });
    } else {
      setState({
        loading: false,
        error: true,
        errorMessage: t("error.wrongParameter"),
      });
    }
  }, [requestKey]);

  return (
    <div className="Container-Main">
      <a href={`${window.location.origin}`}>
        <img src={logo} className="App-logo" alt="logo" />
      </a>
      <img src={header_img_dark} className="App-logo" alt="logo" />
      <div className="App">
        <div className="App-header">
          <div className="App_body">
            <div>
              <h1 className={"title_center"}>
                {!loading
                  ?error
                    ? t("pageOnboardComplete_Error")
                    : t("pageOnboardComplete_Title")
                  : null}
              </h1>
            </div>

            <div className={"panel_onboard"}>
              {loading && (
                <Loading message={t("pageOnboardComplete_Loading")} />
              )}
              {!loading ? (
                error ? (<StepCompletedError lang={lang} errorMessage={errorMessage}/>) :
                ( <StepCompleted lang={lang}/> )
              ) : null}
            </div>

            <SupportLinks country={country} lang={lang}/>
          </div>
        </div>
      </div>
    </div>
  );
}
