import React from 'react';
import { CountryPageProps } from './interfaces/ComponentProps';
import { getSupportLinkByCountryCode } from './helpers/CountryHelper';

function Manual({country}: CountryPageProps) {
    const supportLink = getSupportLinkByCountryCode(country);

    React.useEffect(() => {
      window.location.href = supportLink ?? ""; 
    });
  
    return ( 
      <></>
      
      );
         
 }

 
export default Manual;