import React, { useEffect } from "react";

import phone from './Phone.svg';
import { PageProps } from "./interfaces/ComponentProps";
import { Trans, useTranslation } from "react-i18next";
import i18n from "./i18n";
import { CountryEnum, getInfoPointPhoneByCountryCode } from "./helpers/CountryHelper";

export function SupportLinks({ lang, country }: PageProps) {
    const { t } = useTranslation(['supportLinksHelp']);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        i18n.changeLanguage(lang);
    }, [lang])

    let infoPointPhone = getInfoPointPhoneByCountryCode(country);
    let infoPointPhoneShow = infoPointPhone.slice(3).match(/.{1,2}/g)?.join(' ');
    
    if(country === CountryEnum.Spain) {
        const temp = infoPointPhone?.split("_");
        infoPointPhone = temp?.[0] ?? "";
        infoPointPhoneShow = temp?.[1] ?? "";
    }

    return (
        <div className="App_center">
            <p className="Service-Desk"><strong><em>{t("serviceDeskHeader")}</em></strong></p>
            <div className="App_li_parent">
                <div className="App_li">
                    <p className='App_p1'>
                        <img src={phone} className="App-img-service" alt="phone" />
                        <Trans t={t} i18nKey="serviceDeskContent" values={{infoPointPhone: infoPointPhone, infoPointPhoneShow: infoPointPhoneShow}} components={{ a: <a />, em: <em />, p: <p />, strong: <strong />, space: <Space /> }} />
                    </p>
                </div>
            </div>
        </div>
    );
}

const Space = () => {
    return (<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>)
};