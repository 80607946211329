import { Link, useLocation } from "react-router-dom";
import { CountryPageProps } from "./interfaces/ComponentProps";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import { useEffect } from "react";

function NoMatch({ country }: CountryPageProps) {
  const { t } = useTranslation(['noMatch']);
  const location = useLocation();

  useEffect(() => {
    i18n.changeLanguage(country);
  }, [country]);

  let currentPath = window.location.pathname;

  if (currentPath.endsWith('/')) {
    currentPath = currentPath.slice(0, -1);
  }

  return (
    <div>
      <h2>{t("message")}</h2>
      <p>
        <Link to="" onClick={() => window.location.replace(`${currentPath}/help/${country}`)}>{t("returnButton")}</Link>
      </p>
    </div>
  );
}

export default NoMatch;
