import { useEffect } from "react";
import { LangPageProps } from "./interfaces/ComponentProps";
import { Trans, useTranslation } from "react-i18next";
import i18n from "./i18n";


export function StepCompleted({ lang }: LangPageProps) {
  const { t } = useTranslation(['onBoard']);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    i18n.changeLanguage(lang);
  }, [lang])

  return (
    <div>
      <p className="submitted">
        <strong>
          <Trans t={t} i18nKey="labelRequestCompleted_secondpart" components={{ br: <br />, span: <span />, strong:<strong /> }}/>
        </strong>
      </p>
    </div>

  )
}
