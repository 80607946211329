import React from 'react';
import { CountryConfig } from './interfaces/Config';
import logo from "./Header_Logo.png";

import header_img_dark from "./header.jpg";
import { FluentAccordion, FluentAccordionItem } from './HelpPage';
import CountryGrid from './CountryGrid';

interface PageSelectorProps {
    countries: CountryConfig[];
}

const PageSelector = ({ countries }: PageSelectorProps) => {

    const hasSelfResetCountries = countries.some(country => country.SelfReset);

    return (
        <div className="Container-Main">
            <a href={`${window.location.origin}`}>
                <img src={logo} className="App-logo" alt="logo" onClick={() => window.location.replace(`${window.location.origin}`)} />
            </a>
            <img src={header_img_dark} className="App-logo" alt="logo" />
            <div className="App">
                <CountryGrid countries={countries} mode="onboard" title="On Boarding" />
                {hasSelfResetCountries && <CountryGrid countries={countries} mode="selfReset" title="Reset your Password" />}
            </div>
        </div>
    );
};

const styles = {
    gridContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '30%',
        padding: '0px 20% 0px 20%'
    } as React.CSSProperties,
    countryContainer: {
        textAlign: 'center'
    } as React.CSSProperties,
    flag: {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        objectFit: 'cover',
        objectPosition: 'center'
    } as React.CSSProperties,
    link: {
        textDecoration: 'none',
        color: 'inherit'
    } as React.CSSProperties
};

export default PageSelector;
