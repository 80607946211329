import React from 'react';
import { CountryPageProps } from './interfaces/ComponentProps';
import { useLocation } from 'react-router-dom';

function LegacyOnBoardCompleteRedirect({ country }: CountryPageProps) {
  const search = useLocation().search;
  const requestKey = new URLSearchParams(search).get("requestKey");
  
  React.useEffect(() => {
    const currentPath = process.env.REACT_APP_SiteUrl ?? "";

    window.location.replace(`${currentPath}/${country}/onboardComplete/${country}?requestKey=${requestKey}`);
  }, [country]);

  return null;
}

export default LegacyOnBoardCompleteRedirect;
