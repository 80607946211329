import React from 'react';
import { CountryConfig } from './interfaces/Config';
import logo from "./Header_Logo.png";

import header_img_dark from "./header.jpg";
import { FluentAccordion, FluentAccordionItem } from './HelpPage';

interface CountryGridProps {
    countries: CountryConfig[];
    mode: string;
    title: string;
}

const CountryGrid = ({ countries, mode, title }: CountryGridProps) => {
    const filteredCountries = mode === "selfReset" 
        ? countries.filter(country => country.SelfReset) 
        : countries;

    return (
        <header className="Home-Selection">
            <div className="App_body">
                <div className={"panel_onboard"}>
                    <h2>{title}</h2>
                    Select your Country:
                    <div style={styles.gridContainer}>
                        {filteredCountries.map((country, index) => (
                            <a href={`${window.location.href}${country.Country}/${mode}/${country.Country}`} style={styles.link}>
                                <div key={index} style={styles.countryContainer}>
                                    <img src={country.FlagUrl} alt={`${country.Country} flag`} style={styles.flag} />
                                    <p>{country.Name}</p>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </header>
    );
};

const styles = {
    gridContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '30%',
        padding: '0px 20% 0px 20%'
    } as React.CSSProperties,
    countryContainer: {
        textAlign: 'center'
    } as React.CSSProperties,
    flag: {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        objectFit: 'cover',
        objectPosition: 'center'
    } as React.CSSProperties,
    link: {
        textDecoration: 'none',
        color: 'inherit'
    } as React.CSSProperties
};

export default CountryGrid;
