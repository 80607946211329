import { useEffect } from "react";
import { StepCompletedErrorProps } from "./interfaces/ComponentProps";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import { ErrorMessage } from "./ErrorMessage";


export function StepCompletedError({ lang, errorMessage }: StepCompletedErrorProps) {
  const { t } = useTranslation(['onBoard']);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    i18n.changeLanguage(lang);
  }, [lang])

  return (<>
    <div>
      <p className="submitted">
        <strong>
          {t("labelRequestCompletedError_firstpart")}
        </strong>
      </p>
      <p className="submitted">
        <strong>
          {t("labelRequestCompletedError_secondpart")}
        </strong>
      </p>
    </div>
    <ErrorMessage errorMessage={errorMessage} />
    </>
  )
}
